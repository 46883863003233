<template>
  <div>
    <b-container>
      <b-row class="mt-5">
        <h3 class="text-center">Current Supporters</h3>
      </b-row>
      <b-row>
        <b-col cols="10">
          <ul class="list-group">
            <li
              class="list-group-item d-flex justify-content-between align-items-center"
            >
              SERB-SRG
              <span class="badge badge-primary badge-pill">2023-2026</span>
            </li>
            <li
              class="list-group-item d-flex justify-content-between align-items-center"
            >
              PanCSIR
              <span class="badge badge-primary badge-pill">2024-2026</span>
            </li>
            <li
              class="list-group-item d-flex justify-content-between align-items-center"
            >
              DBT
              <span class="badge badge-primary badge-pill">2024-2027</span>
            </li>
            <li
              class="list-group-item d-flex justify-content-between align-items-center"
            >
              CSIR-Neuromission
              <span class="badge badge-primary badge-pill">2024-2027</span>
            </li>
          </ul>
        </b-col>
      </b-row>
      <b-row class="mt-5">
        <h3 class="text-center">Past Supporters</h3>
      </b-row>
      <b-row>
        <b-col cols="10">
          <ul class="list-group">
            <li
              class="list-group-item d-flex justify-content-between align-items-center"
            >
              SERB Ramanujan Fellowship
              <span class="badge badge-primary badge-pill">2022-2022</span>
            </li>
            <li
              class="list-group-item d-flex justify-content-between align-items-center"
            >
              NSF – XSEDE XRAC grant
              <span class="badge badge-primary badge-pill">2020-2022</span>
            </li>
            <li
              class="list-group-item d-flex justify-content-between align-items-center"
            >
              NIH R21 (Co-Investigator) - R21NS10630
              <span class="badge badge-primary badge-pill">2018-2020</span>
            </li>
            <li
              class="list-group-item d-flex justify-content-between align-items-center"
            >
              NSF – XSEDE XRAC grant
              <span class="badge badge-primary badge-pill">2018-2020</span>
            </li>
            <li
              class="list-group-item d-flex justify-content-between align-items-center"
            >
              Craig.H.Nielsen Post-doctoral fellowship
              <span class="badge badge-primary badge-pill">2016-2018</span>
            </li>
            <li
              class="list-group-item d-flex justify-content-between align-items-center"
            >
              NSF – XSEDE grant for Bioinformatics data analysis
              <span class="badge badge-primary badge-pill">2016-2018</span>
            </li>
          </ul>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.list-group-item {
  font-family: "Oswald", sans-serif !important;
  font-size: 20px;
  font-weight: bold;
  color: #346225 !important;
}
h3 {
  font-family: "Oswald", sans-serif !important;
  font-weight: bold;
}
</style>
